import React from "react";
import ReactDOM from "react-dom";
import "../index.css";
import Physitrack from "../components/physitrack/Physitrack";
import Eform from "../components/eform/Eform";
import LoginForm from "../components/login/LoginForm";

import API from "iprs-api-library";
import { getURLPathArray, getURLSearchParam } from "../lib/iprs-react-library/src/index";
import CaseSave from "../components/pharos/CaseSave";
//WR-487 Temporarily removed. Will be added back in a future release.
//import F2FSelfReferral                          from './components/pharos/F2FSelfReferral';
import OnwardReferralForm from "../components/OnwardReferral/OnwardReferralForm";
import CaseStatusButtons from "../components/pharos/CaseStatusButtons";

import addClientSettingsEditor from "./prod/AddClientSettingsEditor";
import addWebReferralConfigEditor from "./prod/AddWebReferralConfigEditor";

import TabContainer from "../components/ClinicProfile/components/TabController/TabController";
import InvoiceFormStepper from "../components/PaymentAutomation/InvoiceForm/InvoiceFormMaster";

import IprsPortalMIReports from "../lib/iprs-react-library/src/SharedComponents/IprsHealthPortal/IprsPortalMIReports";

import toBoolean from "../lib/iprs-react-library/src/util/toBoolean";

import LoginAndPWReset from "../components/LoginAndPWReset/LoginAndPWReset";
import PasswordReset from "../components/LoginAndPWReset/PasswordReset.jsx";

const globalOverride = toBoolean(process.env.REACT_APP_IPRS_WEB_API_URL_ALLOW_GLOBAL_OVERRIDE);

const weblayerURL = globalOverride
    ? window?.IPRS_ICORE_GLOBAL?.weblayerURL ??
      process.env.REACT_APP_IPRS_WEB_API_URL
    : process.env.REACT_APP_IPRS_WEB_API_URL;

const fileUploadURL = globalOverride
    ? window?.IPRS_ICORE_GLOBAL?.fileUploadURL ??
      process.env.REACT_APP_IPRS_WEB_API_FILE_UPLOAD_URL
    : process.env.REACT_APP_IPRS_WEB_API_FILE_UPLOAD_URL;

const prodIndex = () => {
    const api = new API(null, weblayerURL, fileUploadURL);
    {
        // A bit ugly but pharos stores a token called Token
        // the api stored a session variable called apiToken
        const pharosToken = window.localStorage.getItem("Token");
        if (pharosToken) {
            api.setToken(pharosToken);
        }
    }

    addWebReferralConfigEditor(api, getURLSearchParam);
    addClientSettingsEditor(api);

    try {
        const physitrackElem = document.getElementById("physitrack");
        if (null !== physitrackElem) {
            ReactDOM.render(
                <React.StrictMode>
                    <Physitrack api={api} />
                </React.StrictMode>,
                physitrackElem
            );
        }
    } catch (e) {
        console.log("physitrackElem LOAD ERROR", e);
    }

    {
        const caseSaveElem = document.getElementById("CaseSaveDiv");
        if (null !== caseSaveElem) {
            //api.setAlternateNetworkConnectionFunction(mockNetwork);
            ReactDOM.render(
                <React.StrictMode>
                    <CaseSave api={api} />
                </React.StrictMode>,
                caseSaveElem
            );
        }
    }

    {
        const onholdButtonsDiv = document.getElementById("onhold-buttons-div");
        if (null !== onholdButtonsDiv) {
            //api.setAlternateNetworkConnectionFunction(mockNetwork);
            ReactDOM.render(
                <React.StrictMode>
                    <div>
                        <CaseStatusButtons api={api} />
                        <CaseSave api={api} />
                    </div>
                </React.StrictMode>,
                onholdButtonsDiv
            );
        }
    }

    {
        const onholdButtonsDiv = document.getElementById("post-save-div");
        if (null !== onholdButtonsDiv) {
            //api.setAlternateNetworkConnectionFunction(mockNetwork);
            ReactDOM.render(
                <React.StrictMode>
                    <div>
                        <CaseSave api={api} />
                    </div>
                </React.StrictMode>,
                onholdButtonsDiv
            );
        }
    }

    try {
        /* Eforms are only loaded for non-MSK assessments 
        For portal the element called eform with class caseType-PSYCH is added by the proxy.
        */
        const pharosAssesmentPageRegex      = new RegExp('^/Presentation/Page/PMS/eForm/(Initial|FollowUp).aspx');
        const isPharosAssesmentURL          = pharosAssesmentPageRegex.test(window.location.pathname);
        const isMSKinURL                    = 'msk'     === window.location.search?.caseType?.toLowerCase();
        const isMSKinDoc = doc => {
            const caseTypeHiddenField = doc.getElementById('CaseTypeHiddenField');
            const caseType = caseTypeHiddenField?.value;
            return '2' !== caseType; // MSK is type 1
        }

        const portalContentEl = document.getElementById("eform");
        const isPortalElPsyc = portalContentEl && portalContentEl.classList.contains("caseType-PSYCH");

        const insertForm                    = isPortalElPsyc || 
            (isPharosAssesmentURL && !(isMSKinURL || isMSKinDoc(window.document)));

        if (insertForm) {

            const pharosContentEl = document.getElementById("ApplicationContent");
            const eFormInsertElem = portalContentEl?? pharosContentEl;


            if(eFormInsertElem){
                eFormInsertElem.innerHTML = "";
                ReactDOM.render(
                    <React.StrictMode>
                        <Eform
                            api={api}
                            getURLPathArray={getURLPathArray}
                            getURLSearchParam={getURLSearchParam}
                        />
                    </React.StrictMode>,
                    eFormInsertElem
                );
            }
        }
    } catch (e) {
        console.log("EFORM LOAD ERROR", e);
    }

    // Login
    const LoginElem = document.getElementById("loginForm");
    if (null != LoginElem) {
        /* these used to be done by the proxy. Beware that 
        the react code replaces the form so you won't see
        these elements in the DOM at run time */
    const securityIdEl  = document.getElementById('MemberLoginForm_LoginForm_SecurityID');
    const backUrlEl     = document.getElementById('MemberLoginForm_LoginForm_BackURL');
        const getValue = el => el?.getAttribute('value');
    const securityID = getValue( securityIdEl );
    const backURL = getValue( backUrlEl );
        ReactDOM.render(
            <React.StrictMode>
                <LoginForm securityID={securityID} backURL={backURL} api={api} />
            </React.StrictMode>,
            LoginElem
        );
    }

    // Onward Referral Form
    const OnwardReferralElem = document.getElementById("onwardReferral");
    if (null != OnwardReferralElem) {
        ReactDOM.render(
            <React.StrictMode>
                <OnwardReferralForm api={api} getURLSearchParam={getURLSearchParam} />
            </React.StrictMode>,
            OnwardReferralElem
        );
    }

    try {
        const pharosClinicManagementPageRegex = new RegExp(
            "^/Presentation/Page/Maintenance/ClinicManagement.aspx$"
        );
        const isPharosClinicManagementURL = pharosClinicManagementPageRegex.test(
            window.location.pathname
        );
        if (isPharosClinicManagementURL) {
            const facilityID = window.location.search.match(/FacilityID=(\d+)/)[1];

            const clinicManagementElem =
                document.getElementById("ApplicationContent");
            clinicManagementElem.innerHTML = "";
            if (null != clinicManagementElem) {
                ReactDOM.render(
                    <React.StrictMode>
                        <TabContainer api={api} FacilityID={facilityID} />
                    </React.StrictMode>,
                    clinicManagementElem
                );
            }
        }
    } catch (e) {
        console.log("clinicProfileElem LOAD ERROR", e);
    }


    try {
        const pharosProfilePageNewRegex = new RegExp(
            "^/Presentation/Page/Maintenance/Profile.aspx$"
        );
        const isPharosProfilePageNewURL = pharosProfilePageNewRegex.test(
            window.location.pathname
        );
        if (isPharosProfilePageNewURL) {
            const profilePageElem = document.getElementById("UserFiles");
            profilePageElem.innerHTML = "";
            if (null != profilePageElem) {
                ReactDOM.render(
                    <React.StrictMode>
                        <>
                            <IprsPortalMIReports
                                api={api}
                                PartyID={
                                    document.getElementById("PersonIDHiddenField")
                                        .value
                                }
                            ></IprsPortalMIReports>
                        </>
                    </React.StrictMode>,
                    profilePageElem
                );
          }
      }
    } catch (e) {
        console.log("profilePageElem LOAD ERROR", e);
    }

    try {
        const pharosInvoicePaymentSchedulerPageRegex = new RegExp(
          "^/Presentation/Page/Accounts/InvoicePaymentScheduler.aspx$"
        );
        const isPharosInvoicePaymentPageRegexURL = pharosInvoicePaymentSchedulerPageRegex.test(
          window.location.pathname
        );
        if (isPharosInvoicePaymentPageRegexURL) {
          const invoiceManagementElem =
            document.getElementById("ApplicationContent");
            invoiceManagementElem.innerHTML = "";
          if (null != invoiceManagementElem) {
            ReactDOM.render(
              <React.StrictMode>
                <InvoiceFormStepper api={api}/>
              </React.StrictMode>,
              invoiceManagementElem
            );
          }
        }
      } catch (e) {
        console.log("invoiceManagementElem LOAD ERROR", e);
      }


      try {
        const pharosLoginPageNewRegex = new RegExp(
          "^/Presentation/Login/LoginPageNew.aspx$"
        );
        const isPharosLoginPageNewURL = pharosLoginPageNewRegex.test(
          window.location.pathname
        );
        if (isPharosLoginPageNewURL) {
          const loginPageElem =
            document.getElementById("PasswordReset");
            loginPageElem.innerHTML = "";
          if (null != loginPageElem) {
            ReactDOM.render(
              <React.StrictMode>
                <LoginAndPWReset api={api} />
              </React.StrictMode>,
              loginPageElem
            );
          }
        }
      } catch (e) {
        console.log("loginPageElem LOAD ERROR", e);
      }


      try {
        const pharosPasswordResetPageRegex = new RegExp(
          "^/Presentation/Page/Maintenance/PasswordReset.aspx$"
        );
        const isPharosPasswordResetURL = pharosPasswordResetPageRegex.test(
          window.location.pathname
        );
     
        if (isPharosPasswordResetURL) {
          const urlToken = getURLSearchParam("token");
          const passwordResetElem =
            document.getElementById("ApplicationContent");
            passwordResetElem.innerHTML = "";
          if (null != passwordResetElem) {
            ReactDOM.render(
              <React.StrictMode>
                <PasswordReset api={api} urlToken={urlToken} setIsPwReset={false} />
              </React.StrictMode>,
              passwordResetElem
            );
          }
        }
      } catch (e) {
        console.log("passwordResetElem LOAD ERROR", e);
      }
};

export default prodIndex;

