import React, { useEffect, useState } from "react";

// This component will show up when "Add Directory" button is clicked in the Directory Row.
const NewDirectoryInputRow = ({ addNewDirectoryInFileTree }) => {
    const [directoryName, setDirectoryName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonDisable, setButtonDisable] = useState(false);

    // onChange handler to capture string in the input field of this component.
    const onChangeHandler = (e) => {
        setDirectoryName(e.target.value);
    };

    useEffect(() => {
        if (directoryName === "") {
            setButtonDisable(true);
        } else {
            setButtonDisable(false);
        }
    }, [directoryName]);

    // This function will be triggered when + button is clicked.
    const makeDirectoryButtonHandler = (e) => {
        e.preventDefault()
        setErrorMessage(addNewDirectoryInFileTree(directoryName));
        return false;
    };

    return (
        <>
            <div style={{ paddingLeft: "20px" }}>
                <div className="iprsPortalFileTreeDirectoryIconAndNameDiv">
                    <div className="iprsPortalFileTreeDirectoryIconDiv">
                        <div className="iprsPortalFileTreeDirectioryIconsAndName">
                            <div className="iprsPortalFileTreeNameForDirectoryAndFile">
                                <label className="iprsPortalFileTreeAddNewDirectoryRow">
                                    <input
                                        className="iprsPortalFileTreeAddNewDirectoryRowInput"
                                        type="text"
                                        onChange={onChangeHandler}
                                    ></input>
                                    <button
                                        className="iprsPortalFileTreeItemAddDirectoryButton"
                                        onClick={makeDirectoryButtonHandler}
                                        disabled={buttonDisable}
                                    >
                                        <span>+</span>
                                        <svg width="20" height="20">
                                            <use href="#icon-folder" />
                                        </svg>
                                    </button>

                                    {errorMessage !== "" ? (
                                        <div className="iprsPortalFileTreeAddDirectoryRowErrorMessage">
                                            <p>{errorMessage}</p>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewDirectoryInputRow;